import React from 'react';
import Card from '../components/Card';
import styles from '../style.module.css';

function Home() {
    return (
        <>
        <div className='headertext'>turning ideas into <br></br> real life <div className={styles.linearwipe}>products</div> is my calling</div>
      
      <div className='timeline'>
        <div className='bullet'></div>
        <Card></Card>

       
        
        
        </div>
      <div className=''></div>
        </>
    );
}

export default Home;