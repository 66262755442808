import './style.css';
import insta from './instagram.png';
import link from './linkedin.png';
import what from './whatsapp.png';


function Footer(){
    return(
      <div className='footer'>
        <div className='coloumn'><h3>Aryann</h3><br></br>Connect me on: <br></br><img className='socialpost' src={insta} alt='noimg'></img><img className='socialpost' src={what} alt='noimg'></img><img className='socialpost' src={link} alt='noimg'></img></div>
        <div className='coloumn'><div className='heading'>Projects</div><div className='subheads'>Refinne</div><div className='subheads'>EHR Blockchain</div><br></br></div>
        <div className='coloumn'><div className='heading'>Collaborate on:</div><div className='subheads'>Refinne</div><div className='subheads'>EHR Blockchain</div><br></br></div>
  
      </div>
    )
  }

export default Footer;