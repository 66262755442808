import ref from './images/refinne.png';
import pdt from './images/refinne.png';
import github from './images/github.png';
import './style.css';

function Textbox(props){
  return (
    <>
    <div className='textbox reveal'>
      <img className='boximg' src={props.img} alt="no img"></img>
      <div className='project-text'>{props.text}</div>
      <a href={props.link} className='githublink'><img className='icon' src={github} alt='noimg'></img></a>
    </div>
    </>
  )
}
  
  export default function Card() {
    return (
      <>
      <section>
        <Textbox img={ref} text="Startup Idea which offers discount coupons to their customers at relatively cheaper rates than giant websites like Zomato and Swiggy" link=""/>
        
      </section>
      <section>
        <Textbox img={ref} text="Startup Idea which offers discount coupons to their customers at relatively cheaper rates than giant websites like Zomato and Swiggy" link=""/>
        
      </section>
      <section>
        <Textbox img={ref} text="Startup Idea which offers discount coupons to their customers at relatively cheaper rates than giant websites like Zomato and Swiggy" link=""/>
        
      </section>
      <section>
        <Textbox img={ref} text="Startup Idea which offers discount coupons to their customers at relatively cheaper rates than giant websites like Zomato and Swiggy" link=""/>
        
      </section>
      </>
    );
  }