
import './App.css';
import styles from './style.module.css';
import pdt from './self-img.png';
import React, { useState, useEffect } from 'react';
import Card from './components/Card';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import{ Routes,Route} from 'react-router-dom';
import Home from './Pages/Home';






function Navelement(props){
  return(
    <>
    <div className='logotext'>{props.logotext}</div>
    <div className='navelement'>{props.name}</div>
    <div className='navimg'>{props.src}</div>

    </>
  );
}




function reveal(){
  var reveals = document.querySelectorAll(".reveal");
  for(var i=0;i< reveals.length ;i++ ){
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if(elementTop < windowHeight - elementVisible){
      reveals[i].classList.add("active");
    }
    else{
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll",reveal);


function popup(){
  var reveals = document.querySelectorAll(".reveal");
  for(var i=0;i< reveals.length ;i++ ){
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if(elementTop < windowHeight - elementVisible){
      reveals[i].classList.add("active");
    }
    else{
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll",popup);





function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    },3000);
  }, []);
  

  return (
    <>
    {loading ? (
        <div className="loader-container">
      	  <div className="spinner"><img src={pdt} className='loader-image' alt ='noimg'></img><div className='quote'>HI ! <br></br> I'M ARYANN DWIVEDI</div></div>
        </div>
      ) : (
    
    <div className='whole'>

      
      
      {/* <div className='navbar'>
        <Navelement logotext='Aryann Dwivedi'/>
        <Navelement logotext=' '/>
        
        <HamburgerMenu/>   
      </div> */}
      <Navbar></Navbar>

      <Routes>
        <Route path='/' element={<Home/>}/>


      </Routes>
      
      

      <Footer></Footer>
      
    </div>
    )}
    </>
  );
}


export default App;

