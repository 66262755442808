import React from 'react';
import './style.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";



const Menu = () => {
  return (
    <>
    <div className='object'>Home</div>
    <div className='object'>About</div>
    <div className='object'>Blog</div>
    
    


    </>
  )
}
export default Menu;