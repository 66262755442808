import React, { useState } from 'react';
import close from './close.png';
import open from './open.png';
import Menu from '../Menu';
import logo from '../../logo.png';
import transp from '../../transp.png';

import './style.css';

function Navelement(props){
    return(
      <>
      
      <div className='navimg'><img className='nav-img' src={props.img} ></img></div>
      <div className='logotext'>{props.logotext}</div>
      <div className='navelement'>{props.name}</div>
      
  
      </>
    );
  }

const Navbar = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    // ...
    return (
      <>
            
        {/* <nav className="navbar">
            <ul>
                <nav className="navbar">
                <ul className={`menu-nav${navbarOpen ? ' show-menu' : ''}`}>
                </ul>
                </nav>
            </ul>
        </nav> */}
        <nav className="navbar">
        <Navelement img={logo} logotext='Aryann Dwivedi'/>
        <Navelement img={transp}/>
        
        <button
            className={`toggle${navbarOpen ? 'close' : 'open'}`}
            onClick={() => setNavbarOpen((prev) => !prev)}
        >
            
            <img className='toogleimg' src={navbarOpen ? close : open} alt='noimg'></img>
            
        </button>
           
        
        </nav>
        
        <div className={`menu-nav${navbarOpen ? ' show-menu' : ''}`}>
        <Menu></Menu>
        </div>
        
        
      </>
    );
  };
export default Navbar;